import query from 'query.js';

export const DESKTOP_TYPE_LIMIT = 10;
export const MOBILE_TYPE_LIMIT = 5;
export const MOBILE_MAX_SCREEN_SIZE = '648px';
export const TABLET_MAX_SCREEN_SIZE = '792px';

// WHICH PART OF PAGE SEARCH IS LOCATED
//   directory/redesign/find-a-health-professional
//   redesign/referrals
export const FAP_REFERRALS = 'fap_referrals';
export const HEADER = 'header';

// Additional filter for name search
export const HICAPS = 'HICAPS';

// FOR SEARCH RESULTS
export const PROMOTED_PROFILES = 'promotedProfiles';
export const USERPROFILES = 'userprofiles';
export const FACTSHEETS = 'factsheets';
export const HOSPITALS = 'hospitals';
export const PRACTICE_GROUPS = 'practice_groups';
export const COMMUNITIES = 'communities';
export const PROVIDER_TYPES = 'provider_types';
export const PROVIDER_TYPES_IN_LOCALITIES = 'provider_types_in_localities';
export const SPECIALTIES = 'specialties';
export const SPECIALTIES_IN_HOSPITALS = 'specialties_in_hospitals';
export const SPECIALTIES_IN_LOCALITIES = 'specialties_in_localities';
export const SPECIAL_INTERESTS = 'special_interests';
export const SPECIAL_INTERESTS_IN_LOCALITIES =
    'special_interests_in_localities';
export const TELEHEALTH_SPECIALTIES = 'telehealth_specialties';
export const ORGANISATION_PROFILES = 'organisation_profiles';
export const THREADS = 'threads';
export const LOCALITIES = 'localities';
export const HOSPITAL_LOCALITIES = 'hospital_localities';
export const HEALTH_INFORMATION = 'health_information';
export const STATE = 'state';

export const DEFAULT_SORT_ORDER = [
    SPECIAL_INTERESTS_IN_LOCALITIES,
    USERPROFILES,
    FACTSHEETS,
    HOSPITALS,
    PRACTICE_GROUPS,
    COMMUNITIES,
    SPECIALTIES,
    TELEHEALTH_SPECIALTIES,
    PROVIDER_TYPES_IN_LOCALITIES,
    SPECIALTIES_IN_LOCALITIES,
    SPECIALTIES_IN_HOSPITALS,
    SPECIAL_INTERESTS,
    ORGANISATION_PROFILES,
    THREADS,
    LOCALITIES,
    HOSPITAL_LOCALITIES,
];

export const DEFAULT_FILTER_TYPES = {
    'total': {
        title: 'All Results',
        icon: 'fa-search',
    },
    'results': {
        title: 'All Results',
        icon: 'fa-search',
    },
    [THREADS]: {
        title: 'Q&A',
        icon: 'fa-comment-o',
        colour: 'light-purple',
    },
    'practice_locations': {
        title: 'Practice Locations',
        icon: 'fa-map-marker',
        colour: 'light-orange',
    },
    [PRACTICE_GROUPS]: {
        title: 'Practices',
        icon: 'fa-hospital-o',
        colour: 'light-orange',
    },
    [FACTSHEETS]: {
        title: 'Fact Sheets',
        icon: 'fa-file-text-o',
        colour: 'light-cyan',
    },
    [COMMUNITIES]: {
        title: 'Health Communities',
        icon: 'fa-bookmark-o',
        colour: 'light-red',
    },
    [ORGANISATION_PROFILES]: {
        title: 'Health Organisations',
        icon: 'fa-building-o',
        colour: 'baby-blue',
    },
    [USERPROFILES]: {
        title: 'Health Professionals',
        icon: 'fa-user-md',
        colour: 'light-green',
    },
    [LOCALITIES]: {
        title: 'Specialty Directory',
        icon: 'fa-map-marker',
        colour: 'yellow',
    },
    [HOSPITAL_LOCALITIES]: {
        title: 'Hospital Directory',
        icon: 'fa-map-marker',
        colour: 'light-orange',
    },
    [HOSPITALS]: {
        title: 'Hospitals',
        icon: 'fa-plus-square',
        colour: 'light-orange',
    },
    [PROVIDER_TYPES]: {
        title: 'Provider Type',
        icon: 'fa-plus-square',
        colour: 'light-orange',
    },
    [PROVIDER_TYPES_IN_LOCALITIES]: {
        title: 'Provider Directory',
        icon: 'fa-map-marker',
        colour: 'yellow',
    },
    [SPECIAL_INTERESTS]: {
        title: 'Special Interests',
        icon: 'fa-search',
        colour: 'light-orange',
    },
    [SPECIALTIES]: {
        title: 'Specialties',
        icon: 'fa-stethoscope',
        colour: 'yellow',
    },
    [SPECIALTIES_IN_LOCALITIES]: {
        title: 'Specialty Directory',
        icon: 'fa-map-marker',
        colour: 'yellow',
    },
    [SPECIALTIES_IN_HOSPITALS]: {
        title: 'Hospital Directory',
        icon: 'fa-plus-square',
        colour: 'yellow',
    },
    [SPECIAL_INTERESTS_IN_LOCALITIES]: {
        title: 'Specialty Directory',
        icon: 'fa-map-marker',
        colour: 'yellow',
    },
    [PROMOTED_PROFILES]: {
        icon: 'fa-user-md',
        title: 'Other GPs Also Viewed',
        colour: 'light-green',
    },
    [HEALTH_INFORMATION]: {
        title: 'Health Information',
    },
};

export function formatDisplayedResults(
    facets,
    results,
    displayLimit,
    sortOrder = DEFAULT_SORT_ORDER,
) {
    const displayedResults = {};
    const facetTypes = facets.map((facet) => facet.type);

    // Create ordered facets
    sortOrder.forEach((facetType) => {
        if (facetTypes.indexOf(facetType) !== -1) {
            displayedResults[facetType] = [];
        }
    });

    // Group on objectType
    results.forEach((result) => {
        displayedResults[result.objectType]?.push(result);
    });

    if (!displayLimit || results.length <= displayLimit) {
        return displayedResults;
    } else {
        return limitResultsPerFacet(
            results.length,
            displayedResults,
            displayLimit,
        );
    }
}

export function getResultString(result) {
    if (result.objectType === USERPROFILES) {
        return `${result.text} ${result.extra?.specialties?.join(',') || ''} ${
            result.extra?.location || ''
        }`.trim();
    }
    return result.text;
}

// return a percentage indicating the ratio of matching search terms with result terms
export function getMatchingTermsWeight(searchQuery, results) {
    let weight = 0;
    for (const result of results) {
        const resultString = getResultString(result)?.toUpperCase();
        const escapedSearchQuery = searchQuery
            ?.toUpperCase()
            .trim()
            .split('')
            .map((char) => {
                const isAcceptedCharacter = char.match(/\w|\s|,|-/);
                return isAcceptedCharacter ? char : `\\${char}`;
            })
            .join('');
        const splitEscapedSearchQuery = escapedSearchQuery.split(/[\s,-]+/);
        const matchCount = splitEscapedSearchQuery.reduce((acc, term) => {
            const pattern = new RegExp(`\\b${term}\\b`, 'gi');
            const matches = resultString
                ?.match(pattern)
                ?.reduce(
                    (acc, item) => (acc.includes(item) ? acc : [...acc, item]),
                    [],
                );
            acc += matches?.length || 0;
            return acc;
        }, 0);
        const resultLength = resultString?.split(' ').length || 0;
        const queryLength = splitEscapedSearchQuery.length;

        if (resultLength === 0) {
            weight = 0;
        } else {
            // max 3 decimal places
            const percentage = Number((matchCount / queryLength).toFixed(3));
            if (percentage > weight) {
                weight = percentage;
            }
        }
    }
    return weight;
}

export function formatSpecialtiesInLocalities(specialties, localities) {
    /*
        Given a list of specialties and localities,
        order specialties_in_localities based on the respective mapping order

        Ex:
        specialties: [
            Cardiologist,
            Paediatric Cardiologist
        ]

        localities: [
            Sydney,
            Sydney South,
            The University of Sydney,
            UNSW Sydney,
        ]

        Expected specialties in localities order: [
            Cardiologist in Sydney,
            Cardiologist in Sydney South,
            Paediatric Cardiolgist in Sydney,
            Cardiologist in The University of Sydney,
            Paediatric Cardiologist in Sydney South,
            Cardiologist in UNSW Sydney,
            Paediatric Cardiologist in The University of Sydney,
            Paediatric Cardiologist in UNSW Sydney,
        ]

        Where the respective specialty-locality index combinations are
        (0, 0)
        (0, 1)
        (1, 0)
        (0, 2)
        (1, 1)
        (0, 3)
        (1, 2)
        (1, 3)


        The returned value is the first 5 specialty-locality combination
    */

    const maxFacetLength = Math.max(specialties.length, localities.length);
    const specialtiesInLocalities = [];
    for (let i = 0; i < maxFacetLength; i++) {
        specialties.forEach((sp, spIndex) => {
            localities.forEach((loc, locIndex) => {
                if (spIndex + locIndex === i) {
                    specialtiesInLocalities.push([sp, loc]);
                }
            });
        });
    }
    return specialtiesInLocalities.slice(0, 5).map((spInLoc) => {
        let specialty, locality;
        [specialty, locality] = spInLoc;
        const specialtyId = specialty.id.toString().match(/(\d+)/g)[0];
        const data = {
            extra: {
                specialty: specialty.text,
                locality: locality.text,
            },
            id: `${specialtyId}-${locality.id}`,
            objectType: SPECIALTIES_IN_LOCALITIES,
            text: `${specialty.text} in ${locality.text}`,
        };

        let url = '/directory/';
        const params = {
            specialty: specialtyId,
            locality: locality.id,
        };
        if (specialty.objectType === TELEHEALTH_SPECIALTIES) {
            params.telehealth = true;
        }
        url += query.buildQueryString(params, {fullPath: false});
        data.url = url;

        return data;
    });
}

/**
 * Sorts results based on a prescribed order, `shouldPromote` property and
 * a weighting score derived from search query term matches
 * @param {string[]} sortOrder manually defined order
 * @param {*} results results returned from api
 * @param {{shouldPromote: boolean, termWeight: number}} weighting
 * @returns ordered results
 */
export function sortResults(sortOrder, results, weighting) {
    return Object.entries(results)
        .map(([type, results]) => ({
            type,
            results,
            shouldPromote:
                weighting[type]?.termWeight > 0
                    ? true
                    : weighting[type]?.shouldPromote || false,
            termWeight: weighting[type]?.termWeight || 0,
        }))
        .sort((a, b) => {
            // sort by default sort order first
            const aIndex = sortOrder.findIndex((s) => s === a.type);
            const bIndex = sortOrder.findIndex((s) => s === b.type);
            return aIndex - bIndex;
        })
        .sort((a, b) => {
            // sort by shouldPromote second
            return b.shouldPromote === a.shouldPromote
                ? 1
                : b.shouldPromote - a.shouldPromote;
        })
        .sort((a, b) => {
            // sort by termWeight third
            return b.termWeight === a.termWeight
                ? 1
                : b.termWeight - a.termWeight;
        })
        .reduce((obj, result) => {
            obj[result.type] = result.results;
            return obj;
        }, {});
}

export function reduceAncillarySection(results) {
    const mix = (...arrays) => {
        const reduced = arrays.reduce((acc, row) => {
            row.forEach((value, i) => (acc[i] = [...(acc[i] || []), value]));
            return acc;
        }, []);
        return reduced.reduce((acc, val) => acc.concat(val), []);
    };
    return mix(...results).slice(0, 5);
}

export function combineAncillarySections(results) {
    const returnedResults = {};
    const ancillaryResults = [];

    Object.keys(results)?.forEach((key) => {
        switch (key) {
            case THREADS:
            case FACTSHEETS:
            case ORGANISATION_PROFILES:
            case COMMUNITIES:
                // set health information section in the correct order
                returnedResults[HEALTH_INFORMATION] = [];
                ancillaryResults.push(results[key]);
                break;

            default:
                returnedResults[key] = results[key];
                break;
        }
    });

    if (ancillaryResults.length > 0) {
        returnedResults[HEALTH_INFORMATION] = reduceAncillarySection(
            ancillaryResults,
        );
    }

    return returnedResults;
}

export function formatDisplayedResultsFAP(
    facets,
    results,
    facetLimit,
    displayLimit,
    searchQuery,
    sortOrder = DEFAULT_SORT_ORDER,
) {
    const displayedResults = facets.reduce((obj, {type}) => {
        obj[type] = [];
        return obj;
    }, {});

    const extraResults = [];

    // Group on objectType
    const facetCountLimit = Math.floor(
        Math.min(displayLimit / facets.length, facetLimit),
    );
    let resultsCount = 0;
    results.forEach((result) => {
        if (displayedResults[result.objectType]?.length === facetCountLimit) {
            if (result.objectType !== SPECIALTIES_IN_LOCALITIES) {
                extraResults.push(result);
            }
        } else {
            displayedResults[result.objectType]?.push(result);
            resultsCount++;
        }
    });

    extraResults.forEach((result) => {
        if (resultsCount === displayLimit) {
            return;
        }
        resultsCount++;
        displayedResults[result.objectType]?.push(result);
    });

    // Fetch telehealth specialties and merge with specialties
    const thSpecialties = results.filter(
        (result) => result.objectType === TELEHEALTH_SPECIALTIES,
    );
    if (thSpecialties?.length) {
        thSpecialties.map((thSpecialty) => {
            const spIdx = displayedResults[SPECIALTIES]?.findIndex(
                (sp) => sp.id === thSpecialty.id,
            );
            let telehealthId = thSpecialty.id.toString().match(/(\d+)/g);
            telehealthId.unshift('th');
            telehealthId = telehealthId.join('-');
            thSpecialty.id = telehealthId;
            if (displayedResults[SPECIALTIES]?.length) {
                displayedResults[SPECIALTIES].splice(
                    spIdx + 1,
                    0,
                    thSpecialty,
                );
            } else {
                displayedResults[SPECIALTIES] = [thSpecialty];
            }
        });
    }
    delete displayedResults[TELEHEALTH_SPECIALTIES];

    // add specialties in localities results
    const specialtiesInLocalitites =
        displayedResults[SPECIALTIES_IN_LOCALITIES];
    if (specialtiesInLocalitites?.length) {
        const localities = displayedResults[LOCALITIES] || []; // handle no localities
        displayedResults[LOCALITIES] = [
            ...displayedResults[SPECIALTIES_IN_LOCALITIES],
            ...localities,
        ];
        delete displayedResults[SPECIALTIES_IN_LOCALITIES];
    }

    // add special interests in localities results
    const interestsInLocalities =
        displayedResults[SPECIAL_INTERESTS_IN_LOCALITIES];
    if (interestsInLocalities?.length) {
        const localities = displayedResults[LOCALITIES] || []; // handle no localities
        displayedResults[LOCALITIES] = [
            ...displayedResults[SPECIAL_INTERESTS_IN_LOCALITIES],
            ...localities,
        ];
        delete displayedResults[SPECIAL_INTERESTS_IN_LOCALITIES];
    }

    const weighting = Object.entries(displayedResults).reduce(
        (obj, [type, results]) => {
            const shouldPromote = !!facets.find(
                (f) => f.shouldPromote && f.type === type,
            );
            obj[type] = {
                shouldPromote,
                termWeight: getMatchingTermsWeight(searchQuery, results),
            };
            return obj;
        },
        {},
    );

    const sorted = sortResults(sortOrder, displayedResults, weighting);
    return combineAncillarySections(sorted);
}

function limitResultsPerFacet(resultsLength, displayedResults, displayLimit) {
    const orderedFacets = Object.keys(displayedResults);

    // Limit across all facets
    const finalResults = {};
    let displayCount = 0;
    let loopCounter = 0;
    let currentFacet;
    let currentFacetType;

    while (displayCount < displayLimit && resultsLength > loopCounter) {
        currentFacetType = orderedFacets[loopCounter % orderedFacets.length];
        currentFacet = displayedResults[currentFacetType].pop();

        if (currentFacet) {
            if (finalResults[currentFacetType]) {
                finalResults[currentFacetType].push(currentFacet);
            } else {
                finalResults[currentFacetType] = [currentFacet];
            }
            displayCount++;
        }
        loopCounter++;
    }
    return finalResults;
}
