export default {
    parse(queryString) {
        if (!queryString) {
            queryString = location.search;
        }
        const data = {};
        if (queryString.substring(0, 1) === '?') {
            queryString = queryString.substring(1);
        }

        const args = queryString.split('&');
        for (const arg of args) {
            const parts = arg.split('=');
            const key = parts[0];
            // Don't lose part of value if it has an equal in it
            const val = parts.slice(1).join('=').trim();
            if (val) {
                data[decodeURIComponent(key)] = decodeURIComponent(val);
            }
        }
        return data;
    },

    pathSegment(index) {
        const parts = location.pathname.split(/\//g).filter((s) => s);
        if (index >= 0) {
            return parts[index];
        } else {
            return parts[parts.length + index];
        }
    },

    buildQueryString(
        params,
        options = {fullPath: true, domain: '', pathname: ''},
    ) {
        let result = Object.keys(params)
            .filter((key) => key)
            .map((key) => {
                const encodedKey = encodeURIComponent(key);
                const encodedVal = encodeURIComponent(params[key]);
                const val = encodedVal ? `=${encodedVal}` : '';
                return `${encodedKey}${val}`;
            })
            .join('&');
        if (result) {
            result = `?${result}`;
        }
        if (options.fullPath) {
            let resultHref = '';
            if (options.domain) {
                resultHref = options.domain;
            } else {
                // eslint-disable-next-line max-len
                resultHref = `${location.protocol}//${location.host}${
                    options.pathname
                        ? `/${options.pathname}`
                        : location.pathname
                }`;
            }
            result = `${resultHref}${result}`;
        }
        return result;
    },

    removeParams(params, options = {replace: true, state: {}, title: ''}) {
        if (history.pushState && history.replaceState) {
            const currentParams = this.parse();
            if (!(params instanceof Array)) {
                params = [params];
            }
            for (const param of params) {
                delete currentParams[param];
            }
            const newUrl = this.buildQueryString(currentParams);
            this.setHistory(
                Object.assign({path: newUrl}, options.state),
                options.title,
                newUrl + window.location.hash,
                options.replace,
            );
        }
    },

    addParams(params, options = {replace: true, state: {}, title: ''}) {
        if (history.pushState && history.replaceState) {
            const currentParams = this.parse();
            for (const param of Object.keys(params)) {
                currentParams[param] = params[param];
            }
            const newUrl = this.buildQueryString(currentParams);
            this.setHistory(
                Object.assign({path: newUrl}, options.state),
                options.title,
                newUrl + window.location.hash,
                options.replace,
            );
        }
    },

    setHistory(state, title, url, replace = true) {
        if (replace) {
            history.replaceState(state, title, url);
            // Needed to be like this for IE 11 compatibility
            const evt = document.createEvent('CustomEvent');
            evt.initEvent('urlChanged', true, true);
            document.dispatchEvent(evt);
        } else {
            history.pushState(state, title, url);
        }
    },
};
